<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import Recorder from '@/shared/components/Recorder.vue';
import BaseButton from '../../shared/components/BaseButton.vue';

const store = {
  state: {
    limitGenerateCards: 0,
    usedGenerateCards: 0,
  },
};
export default {
  components: {
    BaseButton,
    Recorder,
  },
  data() {
    return {
      store,
      model: {},
      what_subject_would_you_like_to_learn: '',
      tag: '',
      tag_category: '',
      loading: false,
      cards_generated: false,
      record: '',
      play: '',
    };
  },
  methods: {
    getLimits() {
      store.state.categories = [];
      const listCatPath = '/WebGetLimits.php';
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.limits && json.limits.limit_generate_cards) {
            store.state.limitGenerateCards = json.limits.limit_generate_cards;
            store.state.usedGenerateCards = json.limits.used_generate_cards;
          }
        });
    },
    prepareTag() {
      const listCatPath = '/WebOpenaiPrepareTag.php';
      this.loading = true;

      const data = new FormData();
      data.append('subject', this.what_subject_would_you_like_to_learn);
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
          'Accept-language': window.localStorage.getItem('language'),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.tag && json.tag_category) {
            this.tag = json.tag;
            this.tag_category = json.tag_category;
          }
          this.loading = false;
        });
    },
    generateCards() {
      const listCatPath = '/WebOpenaiGenerateCards.php';
      this.loading = true;

      const data = new FormData();
      data.append('tag', this.tag);
      data.append('tag_category', this.tag_category);
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
          'Accept-language': window.localStorage.getItem('language'),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status) {
            this.cards_generated = true;
            this.getLimits();
          }
          this.loading = false;
        });
    },
  },
  beforeMount() {
    this.getLimits();
  },
};
</script>
<template lang="html">
  <div>
    <div>
      <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1"
             style="margin-right: 40px;display: flex;align-items: center">
          {{ $t("GENERAL.AI_CARDS") }}
        </div>
      </div>
    </div>

    <b-row style="margin: 0 -12.5px!important;">
      <b-col md="12" sm="12">

        <div class="card" style="padding: 16px;height: calc(100vh - 178px)">
          <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 24px">
            <div class="headline-bold-16 basic-1"
                 style="margin-right: 40px;display: flex;align-items: center; text-align: center;
                 justify-content: center; width: 100%">
              {{ $t("TAGS.GENERATE_AI_CARDS") }}
            </div>
          </div>

          <div class="headline-bold-16 basic-1"
               style="margin-right: 40px;display: flex; width: 100%">
            {{ $t("TAGS.HAVE") }} {{ store.state.limitGenerateCards -
          store.state.usedGenerateCards }} {{ $t("TAGS.FREE_TRIAL") }}
          </div>
          <b-row>
            <b-col lg="12" style="padding: 0;">

              <form class="form" v-on:submit.prevent="prepareTag(1)"
                    id="add_dep_form" style="width: 100%;">
                <div class="custom-input">
                  <label>
                    {{ $t("TAGS.WHAT_SUBJECT_WOULD_YOU_LIKE_TO_LEARN") }}
                  </label>
                  <div class="custom-input recorder">
                  <input type="text"
                         v-model="what_subject_would_you_like_to_learn"
                         required
                         maxlength="200"
                  >
                    <Recorder
                        :onChange="(value,rec) => {
                          what_subject_would_you_like_to_learn = value
                        }"
                    />
                  </div>

                  <BaseButton
                    style="position: absolute; right: 100px; bottom: 42px;background: transparent;
                padding: 0;border: 0"
                    class="float-end"
                    :variant="'link'"
                    type="submit">
                    <unicon fill="#1694D0" height="24" width="24" name="arrow-right"/>
                  </BaseButton>
                </div>
              </form>
            </b-col>
          </b-row>
          <b-row v-if="tag_category !== '' && tag !== ''">
            <b-col lg="12" style="padding: 0;">

              <form class="form" v-on:submit.prevent="generateCards(1)"
                    id="add_dep_form" style="width: 100%;">
                <div class="custom-input">
                  <label>
                    {{ $t("TAGS.SUBJECT") }}
                  </label>
                  <input type="text"
                         v-model="tag"
                         required
                         maxlength="200"
                  >
                </div>
                <div class="custom-input">
                  <label>
                    {{ $t("TAGS.CATEGORY") }}
                  </label>
                  <input type="text"
                         v-model="tag_category"
                         required
                         maxlength="200"
                  >
                </div>
                  <div style="display: flex; justify-content: center; width: 100%"
                       v-if="loading === false">
                    <BaseButton
                      style="margin-top: 20px"
                      :variant="'primary'"
                      type="submit">
                      {{$t("TAGS.GENERATE_10_CARD")}}
                    </BaseButton>
                  </div>
              </form>
            </b-col>
          </b-row>
          <b-row v-if="loading === true">
            <b-col lg="12" style="padding: 0; margin-top: 20px">

              <div>
                <div style="display: flex; justify-content: center">
                  <img src="/media/loading_generate_ai_cards.svg" alt=""/>
                </div>
                <div style="display: flex; justify-content: center">
                  <div>{{ $t("TAGS.I_THINK") }}</div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="cards_generated === true">
            <b-col lg="12" style="padding: 0; margin-top: 20px">
              <div>
                <div style="display: flex; justify-content: center; text-align: center">
                 {{ $t("TAGS.CARDS_GENERATED") }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>

</template>
