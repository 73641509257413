<script>

window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const sr = new window.SpeechRecognition();
export default {
  name: 'Recorder',
  props: {
    onChange: Function,
  },
  data() {
    return {
      isRecording: false,
      value: '',
      //  lang_: 'es-ES'
    };
  },
  methods: {
    startSpeechToTxt() {
      // initialisation of voicereco
      //  sr.lang = this.lang_;
      sr.continuous = true;
      sr.interimResults = true;
      sr.onstart = () => {
        this.isRecording = true;
      };

      sr.onend = () => {
        this.isRecording = false;
        this.onChange(this.value, this.isRecording);
      };
      // event current voice reco word
      sr.addEventListener('result', (event) => {
        if (this.isRecording) {
          for (let i = 0; i < event.results.length; i += 1) {
            const result = event.results[i];
            if (result.isFinal) {
              this.isRecording = false;
              sr.stop();
            }
          }

          const text = Array.from(event.results).map((result) => result[0]).map((result) => result.transcript).join('');
          this.value = text;
          this.onChange(text, this.isRecording);
        }
      });

      if (this.isRecording === true) {
        sr.stop();
        this.isRecording = false;
      } else {
        sr.start();
        this.isRecording = true;
      }
    },
  },
};
</script>

<template>
      <div class="recorder-container">
        <unicon v-if="isRecording === false"
                v-on:click="startSpeechToTxt" fill="#FFFFFF" height="24" width="24"
                name="microphone"/>
        <unicon v-if="isRecording === true"
                v-on:click="startSpeechToTxt" fill="#FFFFFF" height="24" width="24"
                name="pause"/>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Fira sans', sans-serif;
}

.custom-input.recorder input, .custom-input.recorder textarea {
  width: calc(100% - 50px);
  margin-right: 50px;
}
.recorder-container{
  border-radius: 50%;
  background-color: #FDAB3D;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  left: unset;
  padding: 8px;
}
.recorder-container .unicon{
  height: 24px;
  width: 24px;
  position: unset;
}
</style>
